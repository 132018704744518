<template>
	<div class="">
		
		<div v-if="categoryCategories">
			<h1 class="font-bold text-2xl text-blue-900 mb-4">{{ category.fields.name }}</h1>
			
			<div 
				v-if="category.fields.youtube"
				class="mb-6 iframeContainer">
				
				<iframe 
					class="iframe"
					:src="`https://www.youtube.com/embed/${category.fields.youtube}`"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
					allowfullscreen 
				/>
			</div>
			
			<p 
				v-if="category.fields.intro"
				v-html="category.fields.intro" 
				class="text-black"
			/>
			
			<hr class="mb-6">
			
			<div 
				v-for="cC in categoryCategories"
				:key="cC.sys.id"
				class="mb-6">
				
				<h3 class="">
					<a
						class="link"
						@click="$emit('navClick', {
							id: cC.sys.id,
							name: cC.fields.name,
							type: 'category',
						})">
						
						{{cC.fields.name}}
					</a>
				</h3>
				
				<p v-if="cC.fields.intro">
					{{cC.fields.intro}}
				</p>
			</div>
		</div>
		
		<div v-if="categoryArticles">
			
			<div 
				v-for="article in categoryArticles"
				:key="article.sys.id"
				class="mb-6">
				
				<h3 class="">
					<a 
						class="link"
						@click="$emit('navClick', {
							id: article.sys.id,
							name: article.fields.title,
							type: 'article',
						})">
						{{article.fields.title}}
					</a>
				</h3>
				
				<p v-if="article.fields.intro">
					{{article.fields.intro}}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:[
		'category',
		'categories',
		'articles',
	],
	
	computed: {
		categoryArticles(){
			if ( 
				!this.articles || 
				!this.articles.items ||
				!this.articles.items.length ) {
				
				return null;
			}
			
			return this.articles.items
				.filter( a => a.fields.category.sys.id == this.category.sys.id)
				.sort( (x,y) => x.fields.order - y.fields.order )
				// .reverse();
		},
		
		categoryCategories(){
			if ( !this.categories || !this.category ) {
				return null;
			}
			
			const categoryParentId = this.category.sys.id;
			// console.log('categoryParentId', categoryParentId);
			
			const response = this.categories.items.filter( c => {
				if ( 
					c.fields.categoryParent &&
					c.fields.categoryParent.sys &&
					c.fields.categoryParent.sys.id &&
					c.fields.categoryParent.sys.id == categoryParentId ){
					
					return true;
				}
			});
			
			return response
				.sort((x, y) => {
					return x.fields.order - y.fields.order;
				});
		},
	},

	data() {
		return {
			// 
		}
	},
	
	methods: {
		
	},
}
</script>

<style lang="scss" scoped>
	.iframeContainer {
		position: relative;
		width: 100%;
		overflow: hidden;
		padding-top: 56.25%; /* 16:9 Aspect Ratio */
		
		.iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}
</style>