var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documentationSidebar"},_vm._l((_vm.categoriesFormated),function(categoryLevel1){return _c('div',{key:categoryLevel1.id},[_c('div',{staticClass:"cursor-pointer",class:_vm.breadcrumb && _vm.breadcrumb.find(b => b.id == categoryLevel1.id) ? 'text-black' : 'text-gray-700',on:{"click":function($event){return _vm.$emit('navClick', {
				id: categoryLevel1.id,
				name: categoryLevel1.name,
				type: 'category',
			})}}},[_vm._v(" "+_vm._s(categoryLevel1.name)+" ")]),(categoryLevel1.categories)?_c('ul',{staticClass:"mt-4 mb-12 block"},_vm._l((categoryLevel1.categories),function(categoryLevel2){return _c('li',{key:categoryLevel2.id,class:_vm.breadcrumb && _vm.breadcrumb.find(b => b.id == categoryLevel2.id) ? 'text-black' : ''},[_c('div',{staticClass:"cursor-pointer hover:text-black",on:{"click":function($event){return _vm.$emit('navClick', {
						id: categoryLevel2.id,
						name: categoryLevel2.name,
						type: 'category',
					})}}},[_vm._v(" "+_vm._s(categoryLevel2.name)+" ")])])}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }