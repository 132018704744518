<template>
	<div class="documentationSidebar">
		<div 
			v-for="categoryLevel1 in categoriesFormated"
			 :key="categoryLevel1.id"
			 class="">
		
			<div 
				@click="$emit('navClick', {
					id: categoryLevel1.id,
					name: categoryLevel1.name,
					type: 'category',
				})"
				class="cursor-pointer"
				:class="breadcrumb && breadcrumb.find(b => b.id == categoryLevel1.id) ? 'text-black' : 'text-gray-700'">
				
				{{ categoryLevel1.name }}
			</div>
			<ul v-if="categoryLevel1.categories"
				class="mt-4 mb-12 block">

				<li 
					v-for="categoryLevel2 in categoryLevel1.categories"
					:key="categoryLevel2.id"
					:class="breadcrumb && breadcrumb.find(b => b.id == categoryLevel2.id) ? 'text-black' : ''">

					<div 
						@click="$emit('navClick', {
							id: categoryLevel2.id,
							name: categoryLevel2.name,
							type: 'category',
						})"
						class="cursor-pointer hover:text-black">
						
						{{ categoryLevel2.name }}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'categories',
		'breadcrumb',
		'categoryId',
	],

	computed: {
		categoriesFormated() {
			if (this.categories && this.categories.items) {
				let categoriesFormated = [];
				// level 1
				this.categories.items.forEach(category => {
					if (!category.fields.categoryParent) {
						categoriesFormated.push({
							id: category.sys.id,
							name: category.fields.name,
							order: category.fields.order,
							categories: [],
						});
					}
				});
				
				// level 2
				this.categories.items.forEach(category => {
					if (category.fields.categoryParent) {
						let parent = categoriesFormated.find(c => c.id == category.fields.categoryParent.sys.id);
						if (parent){
							parent.categories.push({
								id: category.sys.id,
								name: category.fields.name,
								order: category.fields.order,
								// categories: [],
							});
						}
						else {
							console.log('parent not found');
						}
					}
				});
				
				return categoriesFormated
					.sort((x, y) => {
						return x.order - y.order;
					})
					.map(c => {
						c.categories
							.sort((x, y) => {
								return x.order - y.order;
							})

						return c;
					})
			}

			return null;
		},

	},

	data() {
		return {

		}
	},
}
</script>

<style lang="scss" >
	.documentationSidebar {
		ul {
			@apply m-0;
			@apply mb-4;
			@apply p-0;

			li {
				@apply my-2;
				@apply ml-4;
				@apply p-0;
				@apply list-none;
			}
		}
	}
</style>