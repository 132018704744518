<template>
	<!-- class="article" -->
	<div 
		ref="articleRef">
		
		<h1 class="font-bold text-2xl text-blue-900 mb-4">{{ article.fields.title }}</h1>
		
		<p 
			v-if="article.fields.intro"
			v-html="article.fields.intro" 
			class="text-black mb-4"
		/>
		
		<div 
			v-if="article.fields.text"
			v-html="documentRendered" 
			@click="onClickArticle"
		/>
	</div>
</template>

<script>
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'; // INLINES
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
	props:[
		'article',
	],
	
	data() {
		return {
			documentRendered: null,
		}
	},
	
	methods: {
		onClickArticle( clickEvent ){
			clickEvent.preventDefault();
			// console.log('clickEvent', clickEvent);
			
			try {
				const id = clickEvent.target.dataset.id;
				const type = clickEvent.target.dataset.type;
				if (!id) {
					return null;
				}
				
				this.$emit('navClick', {
					id,
					name: 'inline article link',
					type,
				});
			} 
			catch (error) {
				console.log('not a clickEvent on navClick');
			}
			
		},
		
		onDocumentRendered(){
			const options = {
				renderMark: {
					[MARKS.BOLD]: (text) => `<strong>${text}</strong>`,
					[MARKS.CODE]: (text) => `<div class="code-highlight" language="html"><pre>${text}</pre></div>`, // not working tho...
				},
				renderNode: {
					[INLINES.ENTRY_HYPERLINK]: (node) => {
						return `<a data-id="${node.data.target.sys.id}" data-type="article">${node.content[0].value}</a>`;
					},
					// [INLINES.EMBEDDED_ENTRY]: (node, children) => {
					// 	// target the contentType of the EMBEDDED_ENTRY to display as you need
					// 	if (node.data.target.sys.contentType.sys.id === "blogPost") {
					// 		return (
					// 			<a href={`/blog/${node.data.target.fields.slug}`}>            {node.data.target.fields.title}
					// 			</a>
					// 		);
					// 	}
					// },
					// [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
					// 	// target the contentType of the EMBEDDED_ENTRY to display as you need
					// 	if (node.data.target.sys.contentType.sys.id === "codeBlock") {
					// 		return (
					// 			<pre>
					// 				<code>{node.data.target.fields.code}</code>
					// 			</pre>
					// 		);
					// 	}
						
					// 	if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
					// 		return (
					// 			`
					// 			<iframe
					// 				src="${node.data.target.fields.embedUrl}"
					// 				height="100%"
					// 				width="100%"
					// 				frameBorder="0"
					// 				scrolling="no"
					// 				title=${node.data.target.fields.title}
					// 				allowFullScreen=${true}
					// 			/>
					// 			`
					// 		);
					// 	}
					// },
					
					[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
						return (
							`<img
								src="${node.data.target.fields.file.url}"
							/>`
						);
					},
				},
			};
			
			this.documentRendered = documentToHtmlString(this.article.fields.text, options);
		},
	},
	
	created(){
		this.onDocumentRendered();
	}
}
</script>


<style lang="scss">
	// .article {
	// 	a {
	// 		@apply text-blue-600;
	// 	}
		
	// 	img {
	// 		@apply my-6;
	// 	}
		
		
	// }
	
	.code-highlight {
		@apply bg-blue-900;
		@apply rounded;
		
		pre {
			@apply p-3;
			@apply text-xs;
			@apply text-white;
		}
	}
	
</style>