<template>
	<div class="h-full">
		<div class="">
			
			<div class="flex-none bg-gray-100 py-6">
				<div class="container mx-auto flex-none relative flex space-x-3 items-center w-full">
					<div
						v-for="item in breadcrumb"
						:key="'b_'+item.id"
						@click="onNavClick( item )"
						class="cursor-pointer hover:text-black truncate">
						
						{{item.name}}
						<i class="fas fa-chevron-right text-sm text-blue-500 ml-2" />
					</div>
				</div>
			</div>
			
			<div 
				v-if="categories && articles && !loading"
				class="container mx-auto overflow-hidden flex">
				
				<nav class="flex-none sm:py-12 overflow-auto">
					<SupportNavigation 
						:categories="categories" 
						:breadcrumb="breadcrumb"
						:categoryId="categoryId"
						@navClick="onNavClick"
					/> 
				</nav>
				<div class="flex-grow overflow-auto p-6 sm:p-12 ">
					<div class="max-w-2xl">
						<SupportCategory 
							v-if="category"
							:category="category" 
							:articles="articles"
							:categories="categories"
							@navClick="onNavClick"
						/>
						
						<SupportArticle 
							v-if="article"
							:article="article" 
							@navClick="onNavClick"
							:key="'article_'+articleId" 
						/>
					</div>
				</div>
			</div>
			<div 
				v-else
				class="flex-grow flex w-full h-full overflow-hidden items-center justify-center">
				
				Fetching support documentation...
			</div>
		</div>
	</div>
</template>

<script>
	import SupportArticle from '@/components/support/SupportArticle.vue';
	import SupportCategory from '@/components/support/SupportCategory.vue';
	import SupportNavigation from '@/components/support/SupportNavigation.vue';
	
	export default {
		components: {
			SupportArticle,
			SupportCategory,
			SupportNavigation,
		},
		
		computed: {
			categories(){
				let categories = this.$store.getters.getSupportCategories;
				return categories
			},
			
			category(){
				if (
					this.categories &&
					this.categories.items && 
					this.categoryId ) {
					
					return this.categories.items.find( c => c.sys.id == this.categoryId);
				}
				
				return null;
			},
			
			articles(){
				let articles = this.$store.getters.getSupportArticles;
				return articles
			},
			
			article(){
				if (
					this.articles &&
					this.articles.items && 
					this.articleId ) {
					
					return this.articles.items.find( article => article.sys.id == this.articleId);
				}
				
				return null;
			},
			
			breadcrumb(){
				let response = [];
				
				if (
					this.article && 
					this.categories ) {
					
					response.push({
						id: this.articleId,
						name: this.article.fields.title,
						type: 'article',
					});
					
					const categoryId = this.article.fields.category.sys.id;
					const category = this.categories.items.find( c => c.sys.id == categoryId);
					
					response.push({
						id: category.sys.id,
						name: category.fields.name,
						type: 'category',
					});
					
					if ( category.fields.categoryParent ) {
						const categoryParentId = category.fields.categoryParent.sys.id;
						const categoryParent = this.categories.items.find( c => c.sys.id == categoryParentId);
						response.push({
							id: categoryParent.sys.id,
							name: categoryParent.fields.name,
							type: 'category',
						});
					}
				}
				else if ( 
					this.categoryId && 
					this.categories ) {
					
					const category = this.categories.items.find( c => c.sys.id == this.categoryId);
					
					response.push({
						id: category.sys.id,
						name: category.fields.name,
						type: 'category',
					});
					
					if ( category.fields.categoryParent ) {
						const categoryParentId = category.fields.categoryParent.sys.id;
						const categoryParent = this.categories.items.find( c => c.sys.id == categoryParentId);
						response.push({
							id: categoryParent.sys.id,
							name: categoryParent.fields.name,
							type: 'category',
						});
					}
				}
				
				return response.reverse();
			},
		},
		
		// watch: {
		// 	showSupportModal( newVal, oldVal ) {
		// 		// console.log('changed', newVal, oldVal);
		// 		if ( newVal) {
		// 			if ( newVal.articleId ) {
		// 				this.articleId = newVal.articleId;
		// 			}
		// 			else if ( newVal.categoryId ) {
		// 				this.categoryId = newVal.categoryId;
		// 			}
		// 		}
		// 	}
		// },
		
		watch:{
			$route(to, from){
				console.log({to, from});
				
				if ( to.params.categoryId ) {
					this.articleId = null;
					this.categoryId = to.params.categoryId;
				}
				else if ( to.params.articleId ) {
					this.articleId = to.params.articleId;
					this.categoryId = null;
				}
			}	
		},
		
		data() {
			return {
				categoryId: null,
				articleId: null,
				loading: null,
				showIds: false,
				route: null,
			}
		},
		
		methods: {
			onNavClick( navOject ){
				console.log( 'onNavClick', navOject );
				// console.log( navOject );
				
				this.categoryId = null;
				this.articleId = null;
				
				if ( navOject.type == 'article' ) {
					this.articleId = navOject.id;
					this.$router.push( { name: 'documentation-article', params: { articleId: this.articleId } } );
				}
				else if ( navOject.type == 'category' ) {
					this.categoryId = navOject.id;
					this.$router.push( { name: 'documentation-category', params: { categoryId: this.categoryId } } );
				}
			},
			
			async onFetch(){
				try {
					this.loading = 'refreshingSupport';
					await this.$store.dispatch('listSupportCategories');
					await this.$store.dispatch('listSupportArticles');
				} 
				catch (e) {
					// this.$notify({ 
					// 	type: 'error', 
					// 	text: e.message || 'Problem fetching support documentation'
					// });
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
        },
        
        async mounted() {
            await this.onFetch();
			
			if ( 
				!this.$route.params.categoryId && 
				!this.$route.params.articleId) {
				
				this.$router.push( { name: 'documentation-category', params: { categoryId: 'uTh0zNUfrxHBlzGayB6QW' } } );
			}
			else {
				if ( this.$route.params.categoryId ) {
					this.articleId = null;
					this.categoryId = this.$route.params.categoryId;
				}
				else if ( this.$route.params.articleId ) {
					this.articleId = this.$route.params.articleId;
					this.categoryId = null;
				}
			}
			
			
			// if (!this.route) {
			// 	this.route = this.$route;
				
			// 	if ( !this.route.params.categoryId && !this.route.params.articleId) {
			// 		this.$router.push( { name: 'documentation-category', params: { categoryId: 'uTh0zNUfrxHBlzGayB6QW' } } );
			// 	}
			// }
			
			// if (this.$i18n.locale == 'nb') {
			// 	if (localStorage.cloudcast_hideLocaleWarning) {
			// 		// console.log('found it');
			// 		this.hideLocaleWarning = true;
			// 	}
			// 	else {
			// 		this.hideLocaleWarning = false;
			// 	}
			// }
			
			// if ( this.showSupportModal.articleId ) {
			// 	this.articleId = this.showSupportModal.articleId;
			// }
			// else if ( this.showSupportModal.categoryId ) {
			// 	this.categoryId = this.showSupportModal.categoryId;
			// }
		},
	}
</script>

<style lang="scss">
	.link {
		@apply cursor-pointer;
	}
	
</style>