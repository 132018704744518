var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.categoryCategories)?_c('div',[_c('h1',{staticClass:"font-bold text-2xl text-blue-900 mb-4"},[_vm._v(_vm._s(_vm.category.fields.name))]),(_vm.category.fields.youtube)?_c('div',{staticClass:"mb-6 iframeContainer"},[_c('iframe',{staticClass:"iframe",attrs:{"src":`https://www.youtube.com/embed/${_vm.category.fields.youtube}`,"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})]):_vm._e(),(_vm.category.fields.intro)?_c('p',{staticClass:"text-black",domProps:{"innerHTML":_vm._s(_vm.category.fields.intro)}}):_vm._e(),_c('hr',{staticClass:"mb-6"}),_vm._l((_vm.categoryCategories),function(cC){return _c('div',{key:cC.sys.id,staticClass:"mb-6"},[_c('h3',{},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.$emit('navClick', {
						id: cC.sys.id,
						name: cC.fields.name,
						type: 'category',
					})}}},[_vm._v(" "+_vm._s(cC.fields.name)+" ")])]),(cC.fields.intro)?_c('p',[_vm._v(" "+_vm._s(cC.fields.intro)+" ")]):_vm._e()])})],2):_vm._e(),(_vm.categoryArticles)?_c('div',_vm._l((_vm.categoryArticles),function(article){return _c('div',{key:article.sys.id,staticClass:"mb-6"},[_c('h3',{},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.$emit('navClick', {
						id: article.sys.id,
						name: article.fields.title,
						type: 'article',
					})}}},[_vm._v(" "+_vm._s(article.fields.title)+" ")])]),(article.fields.intro)?_c('p',[_vm._v(" "+_vm._s(article.fields.intro)+" ")]):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }